import React from "react";
import RTL from "./RTL"; // Import the RTL component we created earlier
import "./fonts.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme"; // Import the custom theme
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "./bootstrap.min.css";
// import "./components/chart.min.js";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <RTL>
        <App />
      </RTL>
    </Provider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
