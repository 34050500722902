import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Button, TextField } from "@mui/material";
import { clearUserRegister, userRegister } from "../redux/actions/userActions";
import AlertMessage from "../components/AlertMessage";
import SignupSuccess from "../components/SignupSuccess";
import SocialLinks from "../components/SocialLinks";

const SignupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { statusUserRegister } = useSelector((state) => state.userRegister);

  const [email, setEmail] = useState("");
  const [storeName, setStoreName] = useState("");
  const [scopeOfWork, setScopeOfWork] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [address, setAddress] = useState("");

  const handlePhone1Change = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-plus (+) characters
    const maxLengthValue = formattedValue.slice(0, 10); // Limit to maximum length (adjust as needed)
    setPhone1(maxLengthValue);
  };

  const handlePhone2Change = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-plus (+) characters
    const maxLengthValue = formattedValue.slice(0, 10); // Limit to maximum length (adjust as needed)
    setPhone2(maxLengthValue);
  };

  const handlePhone3Change = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-plus (+) characters
    const maxLengthValue = formattedValue.slice(0, 10); // Limit to maximum length (adjust as needed)
    setPhone3(maxLengthValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      userRegister(
        email,
        storeName,
        scopeOfWork,
        phone1,
        phone2,
        phone3,
        address
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearUserRegister());
    };
  }, []);

  return (
    <Container fluid className="main-container">
      <AlertMessage />
      {statusUserRegister === 200 ? (
        <SignupSuccess />
      ) : (
        <form onSubmit={handleSubmit} className="signup-page">
          <div className="signup-container">
            <TextField
              required
              autoComplete="off"
              type="email"
              label="البريد الإلكتروني"
              className="signup-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              required
              autoComplete="off"
              type="text"
              label="اسم المتجر"
              className="signup-input"
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
            />
            <TextField
              required
              autoComplete="off"
              type="text"
              label="مجال العمل"
              className="signup-input"
              value={scopeOfWork}
              onChange={(e) => setScopeOfWork(e.target.value)}
            />
            <TextField
              required
              autoComplete="off"
              type="number"
              label="رقم الهاتف 1"
              className="signup-input"
              value={phone1}
              onChange={handlePhone1Change}
            />
            <TextField
              type="number"
              autoComplete="off"
              label="رقم الهاتف 2"
              className="signup-input"
              value={phone2}
              onChange={handlePhone2Change}
            />
            <TextField
              type="number"
              autoComplete="off"
              label="رقم الهاتف 3"
              className="signup-input"
              value={phone3}
              onChange={handlePhone3Change}
            />
            <TextField
              required
              autoComplete="off"
              type="text"
              label="العنوان"
              className="signup-input"
            />
            <Button variant="contained" type="submit" className="signup-button">
              إنشاء حساب
            </Button>

            <p className="create-account-link">
              لديك حساب بالفعل؟
              <Link to={"/login"}>
                <span> تسجيل الدخول</span>
              </Link>
            </p>
          </div>
        </form>
      )}
      <SocialLinks />
      <br />
    </Container>
  );
};

export default SignupPage;
