import React from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  {
    field: "itemNum",
    headerName: "الرقم",
    width: 200,
  },
  {
    field: "itemName",
    headerName: "الإسم",
    type: "text",
    width: 300,
  },
  {
    field: "price",
    headerName: "السعر",
    width: 200,
  },
  {
    field: "outcome",
    headerName: "الكمية",
    width: 200,
  },
  {
    field: "Total",
    headerName: "الإجمالي",
    width: 200,
  },
];

const StoreOrderDetailsGridView = ({ rows = [] }) => {
  return (
    <Box className="settlements-grid">
      <DataGrid
        style={{ backgroundColor: "var(--background-color)" }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default StoreOrderDetailsGridView;
