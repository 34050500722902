import axios from "axios";
import Cookies from "js-cookie";

const userInfo = Cookies.get("userInfo")
  ? JSON.parse(Cookies.get("userInfo"))
  : {};

const axiosFrmData = axios.create({
  baseURL: "https://fvtion.com/API/talabk",
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${userInfo.token}`,
  },
});

export default axiosFrmData;
